import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { useAuth } from './AuthContext';

const NotificationContext = createContext(null);
const POLLING_INTERVAL = 30000; // 30 seconds

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { api, user } = useAuth();
  const fetchingRef = useRef(false);
  const pollingIntervalRef = useRef(null);
  const mounted = useRef(true);
  const startPollingRef = useRef(null);
  const pendingDeletionsRef = useRef(new Set());

  const stopPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  }, []);

  const fetchNotifications = useCallback(async (silent = false) => {
    // Don't fetch if already fetching or no user
    if (fetchingRef.current || !user) return;
    
    try {
      fetchingRef.current = true;
      if (!silent) setLoading(true);
      setError(null);
      
      const response = await api.get('notifications/');
      
      if (!mounted.current) return;

      const notificationsData = Array.isArray(response.data.results) 
        ? response.data.results 
        : Array.isArray(response.data) 
          ? response.data 
          : [];

      // Filter out any notifications that are pending deletion
      const filteredNotifications = notificationsData.filter(
        n => !pendingDeletionsRef.current.has(n.id)
      );

      setNotifications(filteredNotifications);
      // Calculate unread count from notifications
      const unread = filteredNotifications.reduce((count, notification) => 
        notification.is_read ? count : count + 1, 0);
      setUnreadCount(unread);
      
    } catch (err) {
      if (!mounted.current) return;
      console.error('Error fetching notifications:', err);
      
      if (err.response?.status === 401) {
        stopPolling();
      } else if (err.response?.status === 429) {
        stopPolling();
        // Wait for a minute before retrying
        setTimeout(() => {
          if (mounted.current && user && startPollingRef.current) {
            startPollingRef.current();
          }
        }, 60000);
      }
      
      setError('Failed to fetch notifications');
    } finally {
      if (mounted.current) {
        if (!silent) setLoading(false);
        fetchingRef.current = false;
      }
    }
  }, [api, user, stopPolling]);

  const startPolling = useCallback(() => {
    if (!user || pollingIntervalRef.current) return;
    
    // Initial fetch
    fetchNotifications();

    // Set up polling
    pollingIntervalRef.current = setInterval(() => {
      fetchNotifications(true);
    }, POLLING_INTERVAL);
  }, [fetchNotifications, user]);

  // Store the startPolling function in a ref to break the circular dependency
  useEffect(() => {
    startPollingRef.current = startPolling;
    return () => {
      startPollingRef.current = null;
    };
  }, [startPolling]);

  // Handle component lifecycle and auth state
  useEffect(() => {
    mounted.current = true;
    pendingDeletionsRef.current = new Set();

    return () => {
      mounted.current = false;
      stopPolling();
      pendingDeletionsRef.current.clear();
    };
  }, [stopPolling]);

  // Handle user authentication state
  useEffect(() => {
    if (user) {
      startPolling();
    } else {
      stopPolling();
      // Clear notifications when user logs out
      setNotifications([]);
      setUnreadCount(0);
      setError(null);
      pendingDeletionsRef.current.clear();
    }
  }, [user, startPolling, stopPolling]);

  const markAsRead = useCallback(async (notificationId) => {
    if (!user) return;

    // Optimistic update
    setNotifications(prev => 
      prev.map(n => n.id === notificationId ? { ...n, is_read: true } : n)
    );
    setUnreadCount(prev => Math.max(0, prev - 1));

    try {
      await api.post(`notifications/${notificationId}/mark_read/`);
    } catch (err) {
      console.error('Error marking notification as read:', err);
      // Revert optimistic update
      if (mounted.current) {
        setNotifications(prev => 
          prev.map(n => n.id === notificationId ? { ...n, is_read: false } : n)
        );
        setUnreadCount(prev => prev + 1);
        setError('Failed to mark notification as read');
        setTimeout(() => {
          if (mounted.current) {
            setError(null);
          }
        }, 3000);
      }
    }
  }, [api, user]);

  const markAllAsRead = useCallback(async () => {
    if (!user) return;

    // Optimistic update
    setNotifications(prev => prev.map(n => ({ ...n, is_read: true })));
    setUnreadCount(0);

    try {
      await api.post('notifications/mark_all_read/');
    } catch (err) {
      console.error('Error marking all as read:', err);
      // Revert on error (by re-fetching)
      if (mounted.current) {
        fetchNotifications();
        setError('Failed to mark all notifications as read');
        setTimeout(() => {
          if (mounted.current) {
            setError(null);
          }
        }, 3000);
      }
    }
  }, [api, user, fetchNotifications]);

  const dismissNotification = useCallback(async (notificationId) => {
    if (!user) return;

    // Add to pending deletions
    pendingDeletionsRef.current.add(notificationId);

    // Optimistic update
    setNotifications(prev => {
      const newNotifications = prev.filter(n => n.id !== notificationId);
      const newUnreadCount = newNotifications.reduce((count, n) => 
        n.is_read ? count : count + 1, 0);
      setUnreadCount(newUnreadCount);
      return newNotifications;
    });

    try {
      await api.post(`notifications/${notificationId}/dismiss/`);
      // On success, keep the optimistic update
      pendingDeletionsRef.current.delete(notificationId);
    } catch (err) {
      console.error('Error dismissing notification:', err);
      // On error, remove from pending deletions and refresh
      if (mounted.current) {
        pendingDeletionsRef.current.delete(notificationId);
        fetchNotifications();
        setError('Failed to dismiss notification');
        setTimeout(() => {
          if (mounted.current) {
            setError(null);
          }
        }, 3000);
      }
    }
  }, [api, user, fetchNotifications]);

  const dismissAllNotifications = useCallback(async () => {
    if (!user) return;

    // Add all current notifications to pending deletions
    notifications.forEach(n => pendingDeletionsRef.current.add(n.id));

    // Optimistic update
    setNotifications([]);
    setUnreadCount(0);

    try {
      await api.post('notifications/dismiss_all/');
      // On success, keep the optimistic update
      pendingDeletionsRef.current.clear();
    } catch (err) {
      console.error('Error clearing all notifications:', err);
      // On error, clear pending deletions and refresh
      if (mounted.current) {
        pendingDeletionsRef.current.clear();
        fetchNotifications();
        setError('Failed to clear all notifications');
        setTimeout(() => {
          if (mounted.current) {
            setError(null);
          }
        }, 3000);
      }
    }
  }, [api, user, notifications, fetchNotifications]);

  const value = {
    notifications,
    unreadCount,
    loading,
    error,
    fetchNotifications,
    markAsRead,
    markAllAsRead,
    dismissNotification,
    dismissAllNotifications
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within NotificationProvider');
  }
  return context;
};

export default NotificationProvider;