import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const api = axios.create({
  baseURL: 'https://api.prizecho.com/api/',
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenRefreshing, setTokenRefreshing] = useState(false);

  const clearAuthData = useCallback(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(null);
  }, []);

  const refreshToken = async () => {
    if (tokenRefreshing) return;
    const refreshTokenValue = localStorage.getItem('refresh_token');
    
    if (!refreshTokenValue) {
      clearAuthData();
      throw new Error('No refresh token available');
    }

    try {
      setTokenRefreshing(true);
      // Use axios directly to avoid interceptors
      const response = await axios.post('https://api.prizecho.com/api/token/refresh/', {
        refresh: refreshTokenValue
      });
      
      const newAccessToken = response.data.access;
      localStorage.setItem('access_token', newAccessToken);
      api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
      return newAccessToken;
    } catch (error) {
      console.error('Token refresh error:', error);
      clearAuthData();
      throw error;
    } finally {
      setTokenRefreshing(false);
    }
  };

  api.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Check all conditions before attempting refresh
      if (
        error.response?.status === 401 && 
        !originalRequest._retry && 
        localStorage.getItem('refresh_token') &&
        !originalRequest.url?.includes('token/refresh')
      ) {
        originalRequest._retry = true;
        try {
          const newToken = await refreshToken();
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          clearAuthData();
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await api.get('user/');
      setUser(response.data);
      return true;
    } catch (error) {
      console.error('Error fetching user details:', error);
      clearAuthData();
      return false;
    } finally {
      setLoading(false);
    }
  }, [clearAuthData]);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          await fetchUserDetails();
        } catch (error) {
          console.error('Error initializing auth:', error);
          clearAuthData();
        }
      } else {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [fetchUserDetails, clearAuthData]);

  const login = async (username, password) => {
    try {
      const response = await axios.post('https://api.prizecho.com/api/token/', {
        username,
        password
      });
      const { access, refresh } = response.data;
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      api.defaults.headers.common['Authorization'] = `Bearer ${access}`;
      const success = await fetchUserDetails();
      return success;
    } catch (error) {
      if (error.response?.status === 401) {
        const errorMessage = error.response.data.detail;
        if (errorMessage.includes("User account is disabled")) {
          throw new Error("This account has been locked and scheduled for deletion. It cannot be recovered.");
        }
      }
      console.error('Login error:', error.response?.data || error.message);
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const response = await axios.post('https://api.prizecho.com/api/register/', userData);
      console.log('Registration response:', response.data);
      return response;
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      throw error;
    }
  };

  const logout = useCallback(async () => {
    const refreshTokenValue = localStorage.getItem('refresh_token');
    if (refreshTokenValue) {
      try {
        await api.post('logout/', { refresh_token: refreshTokenValue });
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
    clearAuthData();
  }, [clearAuthData]);

  const fetchLoginHistory = async () => {
    try {
      console.log('Fetching login history...');
      const response = await api.get('login-history/');
      console.log('Login history response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching login history:', error);
      console.error('Error response:', error.response?.data);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      login,
      register,
      logout,
      refreshToken,
      loading,
      fetchLoginHistory,
      api
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;