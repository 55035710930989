import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './AuthContext';
import { CartProvider } from './CartContext';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import Custom404Page from './components/Custom404Page';
import OrderConfirmation from './components/OrderConfirmation';
import NotificationProvider from './NotificationContext';

// Lazy-loaded components
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));
const FBRDocuments = lazy(() => import('./components/FBRDocuments'));
const HomePage = lazy(() => import('./components/HomePage/HomePage'));
const AboutPage = lazy(() => import('./components/AboutPage'));
const ContactPage = lazy(() => import('./components/ContactPage'));
const SearchResults = lazy(() => import('./components/SearchResults'));
const ItemDetailsPage = lazy(() => import('./components/ItemDetailsPage/ItemDetailsPage'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const Profile = lazy(() => import('./components/Profile'));
const EmailVerification = lazy(() => import('./components/EmailVerification'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const Wallet = lazy(() => import('./components/Wallet'));
const JazzCashCallback = lazy(() => import('./components/JazzCashCallback'));
const Cart = lazy(() => import('./components/Cart'));
const Checkout = lazy(() => import('./components/Checkout'));
const NotificationPage = lazy(() => import('./components/NotificationPage'));
const CategoryPage = lazy(() => import('./components/CategoryPage'));


// Global styles
const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: 'Arial', sans-serif;
    background-color: #EBE9E1;
    color: #333;
  }

  .Toastify__toast-container {
    z-index: 9999;
  }

  .Toastify__toast {
    border-radius: 8px;
    padding: 16px;
  }

  .Toastify__toast--success {
    background-color: #4caf50;
  }

  .Toastify__toast--error {
    background-color: #f44336;
  }

  .Toastify__toast--warning {
    background-color: #ff9800;
  }

  .Toastify__toast--info {
    background-color: #2196f3;
  }

  .Toastify__progress-bar {
    height: 3px;
  }

  

  /* Selection style */
  ::selection {
    background-color: #2196f3;
    color: white;
  }
`;

// Styled Components
const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Main = styled.main`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 40px;
  position: relative;
  
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 80px 15px 40px;
  }
  
  @media (max-width: 768px) {
    padding: 70px 15px 30px;
  }

  @media (max-width: 480px) {
    padding: 60px 10px 20px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
`;

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
      <NotificationProvider>
        <Router>
          <CartProvider>
            <GlobalStyle />
            <AppContainer>
              <Header />
              <Main>
                <ErrorBoundary>
                  <Suspense 
                    fallback={
                      <LoadingContainer>
                        <LoadingSpinner />
                      </LoadingContainer>
                    }
                  >
                    <Routes>
                      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                      <Route path="/terms-of-service" element={<TermsOfService />} />
                      <Route path="/fbr-documents" element={<FBRDocuments />} />
                      <Route path="/" element={<HomePage />} />
                      <Route path="/about" element={<AboutPage />} />
                      <Route path="/contact" element={<ContactPage />} />
                      <Route path="/search" element={<SearchResults />} />
                      <Route path="/item/:id" element={<ItemDetailsPage />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/verify-email" element={<EmailVerification />} />
                      <Route path="/verify/:uidb64/:token" element={<EmailVerification />} />
                      <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
                      <Route path="/wallet" element={<Wallet />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route path="/checkout" element={<Checkout />} />
                      <Route path="/wallet/jazzcash-callback" element={<JazzCashCallback />} />
                      <Route path="*" element={<Custom404Page />} />
                      <Route path="/profile/orders/:orderId" element={<OrderConfirmation />} />
                      <Route path="/notifications" element={<NotificationPage />} />
                    </Routes>
                  </Suspense>
                </ErrorBoundary>
              </Main>
              <Footer />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                limit={3}
                toastStyle={{
                  borderRadius: '8px',
                  fontSize: '14px',
                }}
              />
            </AppContainer>
          </CartProvider>
        </Router>
        </NotificationProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;