import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../AuthContext';
import { FaUser, FaCaretDown, FaWallet, FaBars, FaShoppingCart, FaBell } from 'react-icons/fa';
import NotificationMenu from './NotificationMenu';
import { useCart } from '../CartContext';


const IconButton = styled.button`
  background: none;
  border: none;
  color: #EBE9E1;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 15px;

  &:hover {
    color: #EFB11D;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #EFB11D;
  color: #000;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 18px;
  text-align: center;
`;

const HeaderContainer = styled.header`
  background-color: #000000;
  color: #EBE9E1;
  padding: 15px 0;
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: #EFB11D;
  }
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #EBE9E1;
`;

const NavLinks = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  color: #EBE9E1;
  text-decoration: none;
  margin-left: 20px;
  font-size: 16px;
  &:hover {
    color: #EFB11D;
  }
`;

const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

const ProfileIcon = styled(FaUser)`
  color: #EBE9E1;
  font-size: 20px;
  margin-right: 5px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #000000;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 10px;
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  z-index: 1000;
`;

const DropdownItem = styled.div`
  color: #EBE9E1;
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #333;
  }
`;

const MobileMenuIcon = styled(FaBars)`
  display: none;
  color: #EBE9E1;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: ${props => props.$isOpen ? 'flex' : 'none'};
  flex-direction: column;
  background-color: #000000;
  padding: 20px;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (min-width: 768px) {
    display: none;
  }
`;

const WalletIcon = styled(FaWallet)`
  color: #EBE9E1;
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  &:hover {
    color: #EFB11D;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CartIcon = styled(FaShoppingCart)`
  color: #EBE9E1;
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
  &:hover {
    color: #EFB11D;
  }
`;

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, logout } = useAuth();
  const { getCartItemsCount } = useCart();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
    setDropdownOpen(false);
    setMobileMenuOpen(false);
  };

  const handleWalletClick = () => {
    navigate('/wallet');
    setMobileMenuOpen(false);
  };

  const handleCartClick = () => {
    navigate('/cart');
    setMobileMenuOpen(false);
  };

  const handleNotificationsClick = () => {
    navigate('/notifications');
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <HeaderContainer>
      <NavContainer>
        <LogoLink to="/">
          <Logo>Prizecho</Logo>
        </LogoLink>

        <NavLinks>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/about">About</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>

          {user && (
            <ProfileContainer onClick={toggleDropdown} ref={dropdownRef}>
              <ProfileIcon />
              <span>{user.username}</span>
              <FaCaretDown color="#EBE9E1" />
              <DropdownMenu $isOpen={dropdownOpen}>
                <DropdownItem as={Link} to="/profile">Profile</DropdownItem>
                <DropdownItem as={Link} to="/notifications">Notifications</DropdownItem>
                <DropdownItem as={Link} to="/wallet">Wallet</DropdownItem>
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </ProfileContainer>
          )}
          {!user && <StyledLink to="/login">Login</StyledLink>}
        </NavLinks>

        <RightContainer>
          {user && (
            <>
              <NotificationMenu />
              <IconButton onClick={handleCartClick}>
                <FaShoppingCart />
                {getCartItemsCount() > 0 && (
                  <Badge>{getCartItemsCount()}</Badge>
                )}
              </IconButton>
              <IconButton onClick={handleWalletClick}>
                <FaWallet />
              </IconButton>
            </>
          )}
          <MobileMenuIcon onClick={toggleMobileMenu} />
        </RightContainer>
      </NavContainer>

      <MobileMenu $isOpen={mobileMenuOpen}>
        <StyledLink to="/" onClick={toggleMobileMenu}>Home</StyledLink>
        <StyledLink to="/about" onClick={toggleMobileMenu}>About</StyledLink>
        <StyledLink to="/contact" onClick={toggleMobileMenu}>Contact</StyledLink>
        
        {user ? (
          <>
            <StyledLink to="/profile" onClick={toggleMobileMenu}>Profile</StyledLink>
            <StyledLink to="/notifications" onClick={toggleMobileMenu}>Notifications</StyledLink>
            <StyledLink to="/cart" onClick={toggleMobileMenu}>Cart</StyledLink>
            <StyledLink to="/wallet" onClick={toggleMobileMenu}>Wallet</StyledLink>
            <StyledLink as="div" onClick={handleLogout}>Logout</StyledLink>
          </>
        ) : (
          <StyledLink to="/login" onClick={toggleMobileMenu}>Login</StyledLink>
        )}
      </MobileMenu>
    </HeaderContainer>
  );
};

export default Header;