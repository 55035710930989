import styled from 'styled-components';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBell, FaWallet, FaShoppingBag, FaTimes } from 'react-icons/fa';
import { format } from 'date-fns';
import { useAuth } from '../AuthContext';
import { useNotifications } from '../NotificationContext';

const LoadingSpinner = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

const NotificationContainer = styled.div`
  position: relative;
`;

const NotificationIcon = styled.div`
  position: relative;
  cursor: pointer;
  color: #EBE9E1;
  
  &:hover {
    color: #EFB11D;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #EFB11D;
  color: #000;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  min-width: 18px;
  text-align: center;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 45px;
  right: -10px;
  width: 320px;
  background-color: #000000;
  border: 1px solid #333;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  display: ${props => props.$isOpen ? 'block' : 'none'};

  @media (max-width: 480px) {
    width: 300px;
    right: -50px;
  }
`;

const DropdownHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
`;

const Title = styled.h3`
  margin: 0;
  color: #EBE9E1;
  font-size: 16px;
`;

const ViewAll = styled(Link)`
  color: #666;
  text-decoration: none;
  font-size: 14px;
  
  &:hover {
    color: #EFB11D;
  }
`;

const ClearAllButton = styled.button`
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  margin-left: 10px;

  &:hover {
    color: #EFB11D;
  }
`;

const NotificationList = styled.div`
  max-height: 360px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 3px;
  }
`;

const NotificationItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #333;
  position: relative;
  background-color: ${props => props.$unread ? 'rgba(239, 177, 29, 0.05)' : 'transparent'};

  &:hover {
    background-color: rgba(51, 51, 51, 0.5);
  }

  &:last-child {
    border-bottom: none;
  }
`;

const ItemContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

const IconWrapper = styled.div`
  color: ${props => 
    props.$type === 'ORDER_PAID' || props.$type === 'PAYMENT_SUCCESS' ? '#4CAF50' : 
    props.$type === 'ORDER_FAILED' || props.$type === 'PAYMENT_FAILED' ? '#f44336' : 
    props.$type === 'ORDER_PENDING' ? '#2196F3' : '#EFB11D'};
`;

const TextContent = styled.div`
  flex: 1;
  padding-right: 24px;
`;

const ItemTitle = styled.div`
  color: #EBE9E1;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: ${props => props.$unread ? '600' : 'normal'};
`;

const ItemMessage = styled.p`
  color: #999;
  font-size: 13px;
  margin: 0;
  line-height: 1.4;
`;

const TimeStamp = styled.div`
  color: #666;
  font-size: 12px;
  margin-top: 4px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  opacity: 0;
  
  ${NotificationItem}:hover & {
    opacity: 1;
  }

  &:hover {
    color: #EBE9E1;
  }
`;

const EmptyState = styled.div`
  padding: 32px 16px;
  text-align: center;
  color: #666;
`;

const ErrorState = styled(EmptyState)`
  color: #f44336;
`;


const NotificationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    notifications,
    unreadCount,
    loading,
    error,
    fetchNotifications,
    markAsRead,
    dismissNotification
  } = useNotifications();

  useEffect(() => {
    if (user) {
      fetchNotifications();
      // Fetch notifications every 30 seconds
      const interval = setInterval(fetchNotifications, 30000);
      return () => clearInterval(interval);
    }
  }, [user, fetchNotifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleNotificationClick = async (notification) => {
    if (!notification.is_read) {
      await markAsRead(notification.id);
    }
    
    if (notification.data?.order_id) {
      navigate(`/profile/orders/${notification.data.order_id}`);
      setIsOpen(false);
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'ORDER_PAID':
      case 'PAYMENT_SUCCESS':
        return <FaWallet />;
      case 'ORDER_PLACED':
      case 'ORDER_CONFIRMED':
      case 'ORDER_FAILED':
        return <FaShoppingBag />;
      default:
        return <FaBell />;
    }
  };

  const handleDismiss = async (notificationId, event) => {
    event.stopPropagation();
    await dismissNotification(notificationId);
  };

  if (!user) return null;

  return (
    <NotificationContainer ref={menuRef}>
      <NotificationIcon onClick={() => setIsOpen(!isOpen)}>
        <FaBell size={20} />
        {unreadCount > 0 && <Badge>{unreadCount}</Badge>}
      </NotificationIcon>

      <Dropdown $isOpen={isOpen}>
        <DropdownHeader>
          <Title>Notifications</Title>
          <div>
            {notifications.length > 0 && (
              <ViewAll to="/notifications" onClick={() => setIsOpen(false)}>
                View All
              </ViewAll>
            )}
          </div>
        </DropdownHeader>

        <NotificationList>
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : error ? (
            <EmptyState>{error}</EmptyState>
          ) : notifications.length === 0 ? (
            <EmptyState>No notifications</EmptyState>
          ) : (
            notifications.slice(0, 5).map(notification => (  // Show only latest 5 notifications
              <NotificationItem 
                key={notification.id}
                $unread={!notification.is_read}
                onClick={() => handleNotificationClick(notification)}
              >
                <ItemContent>
                  <IconWrapper $type={notification.type}>
                    {getIcon(notification.type)}
                  </IconWrapper>
                  <TextContent>
                    <ItemTitle $unread={!notification.is_read}>
                      {notification.title}
                    </ItemTitle>
                    <ItemMessage>{notification.message}</ItemMessage>
                    <TimeStamp>
                      {format(new Date(notification.created_at), 'MMM d, yyyy h:mm a')}
                    </TimeStamp>
                  </TextContent>
                </ItemContent>
                <CloseButton onClick={(e) => handleDismiss(notification.id, e)}>
                  <FaTimes />
                </CloseButton>
              </NotificationItem>
            ))
          )}
        </NotificationList>
      </Dropdown>
    </NotificationContainer>
  );
};

export default NotificationMenu;