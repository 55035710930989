// src/components/OrderConfirmation.js

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FaCheckCircle, FaHome, FaListAlt } from 'react-icons/fa';

const Container = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const SuccessIcon = styled(FaCheckCircle)`
  color: #4CAF50;
  font-size: 80px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  color: #2e7d32;
  font-size: 24px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  color: #666;
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 1.5;
`;

const OrderNumber = styled.div`
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  
  span {
    font-weight: bold;
    color: #2e7d32;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &.primary {
    background-color: #4CAF50;
    color: white;
    
    &:hover {
      background-color: #45a049;
    }
  }

  &.secondary {
    background-color: #f5f5f5;
    color: #333;
    
    &:hover {
      background-color: #e0e0e0;
    }
  }
`;

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    // Optional: Set a timer to automatically redirect to home after 10 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 10000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Container>
      <SuccessIcon />
      <Title>Order Successfully Placed!</Title>
      <Message>
        Thank you for your order. We've received your payment and are processing your order.
      </Message>
      <OrderNumber>
        Order Number: <span>#{orderId}</span>
      </OrderNumber>
      <Message>
        You will receive a confirmation email shortly with your order details.
      </Message>
      <ButtonContainer>
        <Button className="primary" onClick={() => navigate('/')}>
          <FaHome /> Continue Shopping
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default OrderConfirmation;