import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { FaHome, FaEnvelope } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: 'Arial', sans-serif;
  animation: ${fadeIn} 0.5s ease-in;
`;

const Title = styled.h1`
  font-size: 4rem;
  color: #D6536D;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
`;

const AnimatedImage = styled.img`
  width: 300px;
  height: auto;
  margin-bottom: 30px;
  animation: ${float} 3s ease-in-out infinite;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const NavButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #D6536D;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #C4405C;
    transform: translateY(-3px);
  }

  svg {
    margin-right: 10px;
  }
`;

const Custom404Page = () => {
  return (
    <PageContainer>
      <Title>Oops! Page Not Found</Title>
      <AnimatedImage src="/images/404-prize.webp" alt="Empty Prize Box" />
      <Subtitle>Don't worry, there are plenty more prizes to be found on Prizecho!</Subtitle>
      <NavContainer>
        <NavButton to="/">
          <FaHome /> Home
        </NavButton>
        <NavButton to="/contact">
          <FaEnvelope /> Contact Us
        </NavButton>
      </NavContainer>
    </PageContainer>
  );
};

export default Custom404Page;